<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Хямдралууд</h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
            <router-link to="/add-discount?type=AUTO">
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus">
        <el-tab-pane label="All" name="all">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchOrder">
                    <el-button
                      slot="prepend"
                      icon="el-icon-search"
                      @click="searchOrder"
                    ></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'firstChar'>
                              Нэр А - Я
                           </el-dropdown-item>
                          <el-dropdown-item command = 'lastChar'>
                              Нэр Я - А
                          </el-dropdown-item>
                          <el-dropdown-item command = 'newest'>
                              Шинэ эхэнд
                          </el-dropdown-item>
                          <el-dropdown-item command = 'oldest'>
                              Хуучин эхэнд
                          </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
               </el-col>
              </el-row>
            </div>
            <discount-table :discounts="discounts" :loading="loading" :pageSize="pageSize"  :currentPage="currentPage" :totalCount="totalCount" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></discount-table>
          </div>
        </el-tab-pane><el-tab-pane label="Active" name="active">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchOrder">
                    <el-button
                      slot="prepend"
                      icon="el-icon-search"
                      @click="searchOrder"
                    ></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'firstChar'>
                              Нэр А - Я
                           </el-dropdown-item>
                          <el-dropdown-item command = 'lastChar'>
                              Нэр Я - А
                          </el-dropdown-item>
                          <el-dropdown-item command = 'newest'>
                              Шинэ эхэнд
                          </el-dropdown-item>
                          <el-dropdown-item command = 'oldest'>
                              Хуучин эхэнд
                          </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
               </el-col>
              </el-row>
            </div>
            <discount-table :discounts="discounts.filter(el => el.status == 'active')" :loading="loading" :pageSize="pageSize"  :currentPage="currentPage" :totalCount="totalCount" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></discount-table>
          </div>
        </el-tab-pane><el-tab-pane label="Expired" name="expired">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchOrder">
                    <el-button
                      slot="prepend"
                      icon="el-icon-search"
                      @click="searchOrder"
                    ></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'firstChar'>
                              Нэр А - Я
                           </el-dropdown-item>
                          <el-dropdown-item command = 'lastChar'>
                              Нэр Я - А
                          </el-dropdown-item>
                          <el-dropdown-item command = 'newest'>
                              Шинэ эхэнд
                          </el-dropdown-item>
                          <el-dropdown-item command = 'oldest'>
                              Хуучин эхэнд
                          </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
               </el-col>
              </el-row>
            </div>
            <discount-table :discounts="discounts.filter(el => el.status == 'expired')" :loading="loading" :pageSize="pageSize" :currentPage="currentPage" :totalCount="totalCount" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></discount-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import DiscountTable from './components/discountTable'
export default {
  name: 'orderList',
  components: {
    DiscountTable
  },
  data () {
    return {
      search: '',
      totalCount: 0,
      discounts: [],
      loading: false,
      activeTabStatus: 'all',
      pageSize: 20,
      currentPage: 1,
      searchText: ''
    }
  },

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getDiscounts(from, size)
  },

  methods: {
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'autoDiscount', query: { page: this.currentPage, size: this.pageSize } })
      this.getDiscounts((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'autoDiscount', query: { page: item, size: this.pageSize } })
      this.getDiscounts((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.title_mon.localeCompare(b.title_mon))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.title_mon.localeCompare(a.title_mon))
      } else if (method === 'newest') {
        data.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.discounts = this.sortMethods(data, this.discounts)
    },

    getDiscounts (from, size) {
      const query = '?search_text=' + this.search + '&from=' + from + '&size=' + size + '&type=AUTO'
      this.loading = true
      services.getDiscounts(query).then(response => {
        this.totalCount = response.total
        this.discounts = response.discounts
        this.loading = false
      })
    },
    searchOrder (val) {}
  }
}
</script>
